import React, { useState } from "react";
import Information from "../components/EducationPage/Information/Information";
import Schedule from "../components/EducationPage/Schedule/Schedule";
import Speaker from "../components/EducationPage/Speaker/Speaker";
import Videos from "../components/EducationPage/Videos/Videos";
import Webinars from "../components/EducationPage/Webinars/Webinars";

const EducationPage = () => {
  const [ showContent, setShowContent ] = useState(false);

  const referrer = document.referrer;
  const properDomain = 'user.cgl-limited.net';
  
  if (!referrer.includes(properDomain)) {
    return window.location.replace(`https://${properDomain}/login`);
  } 
  if (referrer.includes(properDomain) && !showContent) {
    setShowContent(true);
  } 

  return (
    showContent &&      
      <div className="">
        <Videos />
        <Information />
        <Webinars />
        <Schedule />
        <Speaker />
      </div>
  );
};

export default EducationPage;
